<template>
  <v-container>
    <v-form @submit.prevent="search()">
      <v-row no-gutters align="center" justify="space-between">
        <v-col class="pr-2 pb-2" cols="12" md="8" xs="4">
          <v-row>
            <v-col cols="12" md="4">
              <span class="p-0 m-0">{{ $t("message.status") }}</span>
              <v-select
                class="mt-1 font-size filter-input-class"
                v-model="filter.filterValue"
                :items="listOfFilters"
                item-text="name"
                item-value="id"
                clearable
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <span class="p-0 m-0">{{ $t("message.stock-less-than") }}</span>
              <v-text-field   min="1" class="mt-1 font-size filter-input-class" v-model="filter.filterStock" type="number" outlined clearable></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="pr-2 pb-2" cols="12" md="auto" xs="4">
          <v-btn rounded color="primary" type="submit" @click.prevent="search()">
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterStock",
  data: () => ({
    iconSearch: mdiMagnify,
    items: ["Active", "Not Active"],
    filter: {
      filterValue: 1,
      filterStock: "",
    },
    storeId: null,
  }),
  computed: {
    listOfFilters() {
      return [
        { id: 1, name: this.$t("message.active") },
        { id: 0, name: this.$t("message.inactive") },
      ];
    },
    storeListData() {
      return this.$store.state.shop_module.shopAllResult;
    },

  },
  methods: {
    search() {
      this.$store.dispatch("itemStock_module/fetchItemStockList", {
        status: this.filter.filterValue === null ? "" : this.filter.filterValue,
        stock: this.filter.filterStock == null || undefined ? "" : this.filter.filterStock
      });
    },
    dispatchStoreList() {
      this.$store.dispatch("shop_module/fetchShopAll");
    },
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections {
  margin: 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* display: none; */
}
#input-415 {
  padding: 0;
}
</style>
