<template>
  <v-container>
    <h2 class="ms-2">{{ $t("message.item-list-title") }}</h2>
    <FilterStock />
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>
    <v-data-table
      :headers="headers"
      :items="itemList"
      class="elevation-0"
      :server-items-length="itemTotal"
      disable-sort
      :options.sync="option"
      :footer-props="{
        'items-per-page-options': [10],
      }"
      :page="currentPage"
      :hide-default-footer="true"
      :hide-default-header="true"

    >
    <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="width: 25%;">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center" style="width: 15%">
          {{ props.headers[6].text }}
        </th>
      </template>
      <template v-slot:[`item.image_url`]="{ item }">
        <v-img
          :lazy-src="notFound"
          :src="item.image_url"
          max-height="150"
          max-width="80px"
          style="margin: 1em 0 1em 0"
        ></v-img>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-layout row-12 justify-space-around style="width: 100%;">
          <v-flex xs1 class="pt-3 ps-0">
            <v-icon
              color="red"
              small
              style="cursor: pointer; font-size: 20px;"
              @click="minStock(item.id)"
            >
              mdi-minus
            </v-icon>
          </v-flex>
          <v-flex
            xs6
            class="col-text-field text-center"
            style="margin-left: 10%;"
          >
            <v-text-field
              v-on:input="changeStock($event, item.id)"
              type="number"
              style="font-size: 16px; text-align: center;"
              class="text-center centered-input m-0"
              outlined
              :value="getItemStock(item.id)"
            ></v-text-field>
          </v-flex>
          <v-flex xs1 class="pt-3 ps-0">
            <v-icon
              color="indigo"
              small
              style="cursor: pointer; font-size: 20px;"
              @click="addStock(item.id)"
            >
              mdi-plus
            </v-icon>
          </v-flex>
        </v-layout>
      </template>

      <template v-slot:top="">
        <!-- dialog delete confirmation -->
        <v-dialog v-model="dialogUpdate" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">{{
              $t("message.confirmation-message")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.prevent="cancelUpdate">
                {{ $t("message.cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click.prevent="updateItemConfirm"
              >
                {{ $t("message.ok") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <v-row
      v-if="itemList.length > 0"
      align="center"
      justify="space-between"
      class="d-flex flex-row-reverse"
      style="margin-bottom: 2%;margin-right:3%; margin-left: 3%;"
    >
      <v-col class="pr-2 pb-2" cols="12" md="1" xs="1">
        <v-btn rounded color="primary" @click="updateItem()">
          {{ $t("message.update-button") }}
        </v-btn>
      </v-col>

      <v-col>
        <v-btn
          :color="'success'"
          rounded
          style="font-size: 10px"
          @click.prevent="resetStock"
        >
          {{ $t("message.reset") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToolbarItem from "@/components/ToolbarItem.vue";
import FilterItem from "@/components/FiltersItem.vue";
import FilterStock from "@/components/FilterStock.vue";
import { mdiMagnify } from "@mdi/js";
import moneyFormat from "../helpers/moneyFormat";

export default {
  name: "ItemStock",
  components: {
    ToolbarItem,
    FilterItem,
    FilterStock,
  },
  data: () => ({
    iconSearch: mdiMagnify,
    option: {},
    page: 1,
    dialogUpdate: false,
    notFound: require("../assets/image-not-found.png"),
  }),

  created() {
    this.initialize();
  },

  methods: {
    changePage(event) {
      return this.$store.dispatch("itemStock_module/fetchItemStockList", {
        page: event,
      });
    },
    resetStock() {
      this.$store.dispatch("itemStock_module/resetStockItem");
    },
    addStock(id) {
      this.$store.dispatch("itemStock_module/updateStockItem", {
        id: id,
        operator: "+",
      });
    },
    minStock(id) {
      this.$store.dispatch("itemStock_module/updateStockItem", {
        id: id,
        operator: "-",
      });
    },
    changeStock(value, id) {
      this.$store.dispatch("itemStock_module/updateStockItem", {
        id: id,
        operator: "custom",
        value: value,
      });
    },
    getItemStock(id) {
      let addItemStock = this.$store.state.itemStock_module.addItemStock;
      let key = addItemStock.findIndex((x) => x.id === id);
      let stock = 0;
      if (addItemStock[key] !== undefined) {
        stock = addItemStock[key].qty;
      }
      return stock;
    },
    async initialize() {
      const { page } = this.option;
      let status = this.$store.state.itemStock_module.filter.filterStatus;
      await this.$store.dispatch("itemStock_module/fetchItemStockList", {
        status: status,
        page: page,
      });
    },
    updateItemConfirm() {
      let payloadAddStock = this.$store.state.itemStock_module.addItemStock;
      this.$store.dispatch("itemStock_module/updateStock", {
        payload: payloadAddStock,
      });
      this.dialogUpdate = false;
    },
    cancelUpdate() {
      this.dialogUpdate = false;
    },
    updateItem() {
      this.dialogUpdate = true;
    },
  },

  computed: {
    isLength() {
      return this.$store.state.itemStock_module.is_length;
    },
    lengthPage() {
      return this.$store.state.itemStock_module.length_page;
    },
    itemList() {
      let itemData = this.$store.state.itemStock_module.itemStockList;
      if (!itemData) {
        return 0;
      } else {
        itemData.map((el) => {
          el.price = moneyFormat(el.price);
        });
      }
      return itemData;
    },
    itemTotal() {
      let resp = this.$store.state.itemStock_module.itemStockHeader;
      return resp.total;
    },
    // itemPerPage() {
    //   let resp = this.$store.state.itemStock_module.itemStockHeader;
    //   if (resp.per_page === undefined) {
    //     return 1;
    //   }
    //   return resp.per_page;
    // },

    currentPage() {
      let resp = this.$store.state.itemStock_module.itemStockHeader;
      // if (resp.current_page === undefined) {
      //   return 1;
      // }
      return resp.current_page;
    },
    headers() {
      return [
        {
          text: this.$t("message.id"),
          align: "start",
          value: "id",
          width: "5%",

        },
        { text: this.$t("message.shop"), value: "from_store", width: "15%" },
        {
          text: this.$t("message.image"),
          value: "image_url",
          align: "center",
          width: "10%",
        },
        {
          text: this.$t("message.name"),
          value: "item_name",
          align: "center",
        },
        {
          text: this.$t("message.price"),
          value: "price",
          align: "center",
          width: "10%",
        },
        // {
        //   text: this.$t("message.status"),
        //   value: "status",
        //   align: "center",
        //   width: "13%",
        // },
        {
          text: this.$t("message.stock"),
          value: "stock",
          align: "center",
          width: "10%",
        },
        {
          text: this.$t("message.action"),
          align: "center",
          width: "8%",
          value: "actions",
        },
      ];
    },
  },

  watch: {
    // option: {
    //   handler() {
    //     this.initialize();
    //   },
    //   deep: true,
    // },
    dialogUpdate(val) {
      val || this.cancelUpdate();
    },
  },
};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.centered-input {
  text-align: start;
  width: fit-content;
  padding-top: 0px;
}
.col-text-field {
  padding: 0px;
  width: fit-content;
  text-align: start;
  margin-top: 10px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

.centered-input .v-text-field__details {
  display: none !important;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
/* .v-application .text-center {
  text-align: start !important;
} */
</style>
